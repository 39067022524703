/* global analytics */
/* eslint class-methods-use-this : 0 */

// create event for tracking events to be called that
// rely on segment being ready
const SegmentReady = new CustomEvent("SegmentReady");

// create event for tracking events to be called that
// rely on identify being called first (e.g. those that are linked to users)
const SegmentOnIdentifyWasCalledEvent = new CustomEvent(
  "SegmentOnIdentifyWasCalled",
);

analytics.on("identify", () => {
  window.dispatchEvent(SegmentOnIdentifyWasCalledEvent);
});

class Segment {
  setupLinkTracking(selector) {
    // attach our track link event to all links with a data-track-link-event
    // attribute
    [].map.call(document.querySelectorAll(selector), (el) => {
      const trackingProps = JSON.parse(el.dataset.trackingProps || "{}");
      analytics.trackLink(el, el.dataset.trackingEvent, trackingProps);
    });
  }
}

window.segment = new Segment();
window.segment.setupLinkTracking("a[data-tracking-event]");
window.segment.ready = true;
window.dispatchEvent(SegmentReady);
